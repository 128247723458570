import {IBookingInfo} from "../../../types/booking-info";

export const BOOKING__SET_DATA = 'BOOKING__SET_DATA';
export const BOOKING__UPDATE = 'bookingsUpdate';
export const BOOKING__GET_ON_DATE = "bookingsGet"



export const actionsBookingInfo = {
    getOnDate : (payload : {date: string, items: IBookingInfo[]}) => ({type: BOOKING__GET_ON_DATE, payload}),
    set: (payload: any) => ({type: BOOKING__SET_DATA, payload}),
    update: (
        data: { [k in keyof IBookingInfo]?: string | number },
        activeDay: string
    ) => ({type: BOOKING__UPDATE, payload: {data, activeDay}}),
}


const initialState : {[k : string] : IBookingInfo[]} | null = null;

export default function bookingInfo(state = initialState, action : any) {
    switch (action.type) {
        case BOOKING__GET_ON_DATE : {
            const payload = action.payload as {date: string, items: IBookingInfo[]};
            return {...state, [payload.date] : payload.items};
        }
        case BOOKING__UPDATE :
            if (state === null) return null;
            return {...state,[action.payload.activeDay] : state[action.payload.activeDay].map((item) => {
                if (item.id !== action.payload.data.id) return item;

                return {...item, ...action.payload.data}
                })}
        case BOOKING__SET_DATA:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
