const SHOW = "confirmWindowShow";
const CLOSE = "confirmWindowClose"

export const actionsConfirmWindow = {
    show : (payload : any) => ({type : SHOW, payload}),
    close : ()=>({type : CLOSE}),
}

const initialState = null;

export default function confirmWindow(state = initialState, action : any) {
    switch (action.type) {
        case SHOW :
            return {...action.payload}
        case CLOSE:
        default:
            return null;
    }
}
