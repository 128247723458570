import React, {useState} from 'react';
import {useAuth} from "../../contexts/auth";
import api from "../../api";
import './auth.scss';

type TAuth = {
    login: string,
    password: string,
}

const Auth = () => {

    const [formData, setFormData] = useState<TAuth>({login: '', password: '',});
    const [formError, setFormError] = useState(false);
    const {authByLogin} = useAuth();

    const handleChange = (prop: keyof TAuth) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [prop]: event.target.value})
        setFormError(false)
    }

    const onSubmit = () => {

        api.user.auth(formData).then(r => {
            if (r.error === true) setFormError(true);

            if (r.error === false) authByLogin(r);

        })
    }

    return (
        <div className="auth">
            <div className="auth__container">
                <div className="auth__title">Авторизация</div>
                <div className="auth__body">

                    {formError && <div className="is-error">Вы ввели неправильный логин или пароль</div>}

                    <div className="auth__form-control">
                        <input type="text" placeholder="Логин" name="Login" value={formData.login}
                               onChange={handleChange('login')}/>
                    </div>
                    <div className="auth__form-control">
                        <input type="password" placeholder="Пароль" name="Password" value={formData.password}
                               onChange={handleChange('password')}/>
                    </div>
                </div>
                <div className="auth__submit">
                    <div className="btn btn-primary btn-block" role="button" style={{cursor: "pointer"}}
                         onClick={onSubmit} onTouchEnd={onSubmit}
                    >
                        Авторизоваться
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;
