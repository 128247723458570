import React, {createContext, useContext, useEffect, useState} from "react";
import api from "../api";
import Preloader from "../components/@share-components/preloader/preloader";
import {useHistory} from 'react-router-dom';
import {siteRoutes} from "../constants/siteRoutes";

type TUserAccount = {
    capabilities: {
        can_basic_administration: boolean
        can_book_without_deposit: boolean
        can_booking_edit: boolean
        can_booking_list: boolean
        can_company_edit: boolean
        can_deposit_edit: boolean
        can_list_deposits: boolean
        can_manage_files: boolean
        can_read_syslogs: boolean
        can_table_edit: boolean
        can_user_management: boolean
        front_can_access_admin: boolean
        front_can_access_manager: boolean
        root: boolean
    }
    groupId: number// TODO: this should not be here, yet on first page load it is.
    group_id: number
    login: string
    uid: string
    token : string
}

interface Auth {
    user: TUserAccount | null,
    authByLogin: (data: TUserAccount) => void,
    isAuthenticated: boolean,
    logout: () => void
}


const AuthContext = createContext({} as Auth);

export const AuthProvider = ({children}: any) => {
    const [user, setUser] = useState<TUserAccount | null>(null);
    const tokenName = process.env.REACT_APP_LOCALSTORAGE_TOKEN_NAME || "token";
    const [showPreloader, setShowPreloader] = useState(true);
    const history = useHistory();

    const redirectAfterLogin = () => history.push(siteRoutes.home);


    const redirectAfterLogout = () => history.push(siteRoutes.home);

    const logout = () => {
        localStorage.removeItem(tokenName)
        setUser(null)
        redirectAfterLogout();
    }

    //При первом запуске страницы - проверяем есть ли токен в сторе. Если он есть - запрашиваем данные на сервере
    useEffect(() => {
        const token = localStorage.getItem(tokenName);

        // Если токен есть - проверяем
        if (token !== null && user === null) {
            api.user.checkUser(token).then(r => {
                if (r.error === false) {
                    setUser({...r, token : token});
                    localStorage.setItem(tokenName, token)
                    setShowPreloader(false)
                } else {
                    logout();
                    setShowPreloader(false)
                }
            })
        } else {
            setShowPreloader(false)
        }

    }, [tokenName, logout, user])

    if (showPreloader) return <Preloader/>

    // Авторизация по связке Логин/Пароль
    const authByLogin = (data: any) => {
        setUser(data);
        localStorage.setItem(tokenName, data.token);
        redirectAfterLogin();
    }

    return (
        <AuthContext.Provider value={{user, isAuthenticated: !!user, logout, authByLogin}}>
            {children}
        </AuthContext.Provider>
    )
}


export const useAuth = () => {
    const authContext = useContext(AuthContext)
    return authContext;
}
