const apiServer = process.env.REACT_APP_API_SERVER || "";
export const apiRoutes = {

    main : apiServer,

    user : apiServer + "/users",

    bookings : apiServer + '/bookings',

    companies :  `${apiServer}/companies` ,

    tables : `${apiServer}/tables`,

    // События компании
    events : `${apiServer}/companies/daily-text-events`,

    //Закрытие  дня
    lockDown : `${apiServer}/company/lockdown/special-services`,
}

