import React from 'react';

import './App.scss';
// ставим локаль moment.js для всего проекта
import 'moment/locale/ru';
import moment from "moment";
import {isIOS} from "react-device-detect";
import ProjectsRouter from './routes'

// ставим локаль moment.js для всего проекта
moment.locale('ru');

if (isIOS) {
    document.addEventListener('touchmove', function (event: any) {

        const scale = (event.scale);

        if (scale !== 1) {
            event.preventDefault();
        }
    }, {passive: false});

    let lastTouchEnd = 0;
    document.addEventListener('touchend', function (event: any) {
        let now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);
}

function App(props : any) {
    return <ProjectsRouter />
}

export default App;

