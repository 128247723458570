export const siteRoutes = {
    home  : "/",
    user: {
        auth : "/user/auth"
    },
    manager : {
        root: "/manager/",
        orderInfo : "/manager/order-info/:date/:orderId/",
        search : "/manager/search/:date?/",
        orderEdit : "/manager/order-edit/:date?/:tableId?/:orderId?/",
        delete : "/manager/order-delete/:date/:orderId/",
        waitingList : "/manager/waiting-list/",
        dashboard : "/manager/dashboard/:date/"
    },

    clear : '/clear/',
    admin : {
        events : '/admin/events/:year?/',
        lockdown: "/admin/close-full-day/:year?/",
        plan : '/admin/plan/',
        customPriceTables : "/admin/custom-price-tables/:year?/",
        customPriceTablesByDate : "/admin/custom-price-tables-by-date/:date?/",
    }
}
