import React from "react";
import {Button, Container} from "react-bootstrap";

export default function ClearTokens(props : any){

    function clearHandler(){
        localStorage.removeItem('tokenX');
        localStorage.removeItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN_NAME || "token")
        window.location.href = '/';
    }

    return <Container>
        <div className="mb-3"/>
        <Button onClick={clearHandler}>Очистить токены</Button>
    </Container>
}
