import React from 'react';
import Auth from "../../../components/auth";

/**
 * Страница авторизации
 *
 * @constructor
 */
const AuthPage = () => <Auth />

export default AuthPage;
