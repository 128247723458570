import {IUiSnackbar} from "../../components/ui/snackbar";

const SHOW = "snackbarShow";
const CLOSE = "snackbarClose"

export const actionsSnackbar = {
    show: (payload: IUiSnackbar) => ({type: SHOW, payload}),
    close: () => ({type: CLOSE}),
    showSuccess: (label: string) => actionsSnackbar.show({type: "success", label}),
    showError: (label: string) => actionsSnackbar.show({type: "error", label}),
}

const initialState: IUiSnackbar = {label: "", show: false, type: "success"};

export default function snackbar(state = initialState, action: any) {
    switch (action.type) {
        case SHOW :
            return {...action.payload, show: true}
        case CLOSE:
            return {...state, show: false}
        default:
            return {...state};
    }
}
