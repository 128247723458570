export const API_POINT = process.env.REACT_APP_API_SERVER.toString();
export const WEBSOCKET_POINT = process.env.REACT_APP_WEBSOCKET_POINT.toString();

export const API_POINT_BOOKING = API_POINT + '/bookings';

export const AUTH__SET_DATA = 'AUTH__SET_DATA'; //Добавляем данные об авторизации
export const AUTH__REMOVE_DATA = 'AUTH__REMOVE_DATA'; //Добавляем данные об авторизации

export const TIME_SET = 'TIME_SET';

export const ARRAY_GUESTS_TEXT = [" гость", ' гостя', ' гостей'];
export const ARRAY_HOURS_TEXT = [" час", ' часа', ' часов'];

export const ORDER_STATUS_BOOKED = 'booked'; // Столик забронирован
export const ORDER_STATUS_ARRIVED = 'arrived'; // Гость пришел
export const ORDER_STATUS_LOCKED = 'locked'; // Столик заблокирован гостем на 15 минут
export const ORDER_STATUS_OPERATOR_LOCK = 'operatorLock'; // Столик заблокирован администратором на 2 дня
export const ORDER_STATUS_NOT_ARRIVED = 'notArrived'; // Гость не пришел



export const TEXT_ORDER_STATUS_UPDATE = 'Статус брони обновлен';

export const dayFormat = {
    full: "YYYY-MM-DDTHH:mm:ss",
    short: "YYYY-MM-DD",
}

