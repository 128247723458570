import {userApi} from "./user";
import {bookingsApi} from "./bookings";
import {adminApi} from "./admin";
import {tablesApi} from "./tables";

/**
 * Основной файл под api
 */

const api = {
    admin : adminApi,

    user: userApi,

    booking: bookingsApi,

    tables : tablesApi,
}

export default api;
