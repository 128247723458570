import {toFetch} from "../components/manager/utils/utils";
import {apiRoutes} from "./_routes";
import {ICompanyTable, ICompanyTableNew} from "../types/table";
import {actionsSnackbar} from "../redux/reduсers/snackbar";
import {ICompanyInfo} from "../types/redux";
import {IEvent} from "../types/events";

export const adminApi = {
    plan: {
        get: (token: string) => {
            return Promise.all([
                toFetch(apiRoutes.companies, {"method": "CompaniesList", token,}).then(r => r.json()),
                toFetch(apiRoutes.tables, {"method": "TablesList", token}).then(r => r.json())
            ]).then(([company, tables]) => ({company: company.items[0], tables: tables.items}))
        },

        /**
         * Добавление нового столика
         * @param table
         * @param token
         */
        addNewTable: (table: ICompanyTableNew, token: string) => {
            const request = {
                "method": "TableAdd",
                "token": token,
                "item": table,
            };

            return toFetch(apiRoutes.tables, request).then(r => r.json())

        },

        /**
         * Удаление столика по id
         */
        deleteTable: (tableId: string, token: string) => {
            const request = {
                "method": "TableDelete",
                "token": token,
                "id": tableId,
            }
            return toFetch(apiRoutes.tables, request).then(r => r.json())
        },

        /**
         * Обновление данных стола
         *
         * @param table
         * @param token
         */
        tableUpdate: (table: ICompanyTable, token: string) => {
            return (dispatch: any) => {
                const request = {
                    "method": "TableEdit",
                    "token": token,
                    "id": table.id,
                    "item": table
                };

                return toFetch(apiRoutes.tables, request).then(r => r.json()).then(json => {
                        if (json.error === true) {
                            dispatch(actionsSnackbar.showError('Ошибка отправки данных'))
                            console.error(json);
                        } else {
                            // Хз что выводить так как на каждый чих идет обновление
                        }
                    }
                )
                    .catch(error => {
                        dispatch(actionsSnackbar.showError('Ошибка связи с сервером'))
                        console.error(error);
                    });
            }


        }
    },

    companyInfo: {
        /**
         * Получение данных об организации
         *
         * @param token
         */
        get: (token: string) => {
            const request = {
                method: 'CompaniesList',
                token,
            };

            return toFetch(apiRoutes.companies, request).then(r => r.json());
            // .then(
            //     json => {
            //         if (json.error === true) {
            //             //TODO прописать обработчик ошибки
            //             return {error: 'Ошибка получения данных о компании'}
            //         }
            //
            //         if (json.error === false) {
            //             return (json.items[0])
            //         }
            //     }
            // )
            // ;
        },

        /**
         * Сохранение данных об организации
         */
        update: (token: string, info: ICompanyInfo) => {
            let item = {...info};

            const request = {
                method: "CompanyEdit",
                token,
                id: item.id,
                item
            };

            return toFetch(apiRoutes.companies, request).then(res => res.json());
        },
    },

    // События чзаведения
    events: {
        // Добавление
        add: (props: IEvent, token: string) => {
            const request = {...props, token, method: "DailyEventAdd"};
            return toFetch(apiRoutes.events, request).then(r => r.json());
        },
        // Обновление
        update: function (props: IEvent, token: string) {
            const request = {...props, token, method: "DailyEventEdit"};
            return toFetch(apiRoutes.events, request).then(r => r.json());
        },

        // Удаление
        delete: function (id: string, token: string) {
            const request = {id, token, method: "DailyEventDelete"};
            return toFetch(apiRoutes.events, request).then(r => r.json());
        },

        // Получение списка на год
        get: function (token: string, year: string) {
            const body = {
                "method": "DailyEventList",
                "token": token,
                "date_from": year + '-01-01',
                "date_to": year + '-12-31',
            };
            return toFetch(apiRoutes.events, body).then(r => r.json());
        }
    },

    lockdown: {
        add: function (props: IEvent, token: string) {
            const request = {...props, token, method: "SpecsLockdownAdd"};
            return toFetch(apiRoutes.lockDown, request).then(r => r.json());
        },
        update: function (props: IEvent, token: string) {
            const request = {...props, token, method: "SpecsLockdownEdit"};
            return toFetch(apiRoutes.lockDown, request).then(r => r.json());
        },
        delete: function (id: string, token: string) {
            const request = {id, token, method: "SpecsLockdownDelete"};
            return toFetch(apiRoutes.lockDown, request).then(r => r.json());
        },
        get: function (token: string, year: string) {
            const body = {
                "method": "SpecsLockdownList",
                "token": token,
                "date_from": year + '-01-01',
                "date_to": year + '-12-31',
            };
            return toFetch(apiRoutes.lockDown, body).then(r => r.json());
        }
    }
}
