import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import store from "./redux/store";
import {AuthProvider} from "./contexts/auth";
import Preloader from "./components/@share-components/preloader/preloader";
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
    <React.Suspense fallback={<Preloader/>}>
        <Router>
            <AuthProvider>
                <Provider store={store}>
                    <App/>
                </Provider>
            </AuthProvider>
        </Router>
    </React.Suspense>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
