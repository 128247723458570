import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useAuth} from "../contexts/auth";
import HomePage from "../pages/home-page";
import {siteRoutes} from "../constants/siteRoutes";
import AuthPage from "../pages/user/auth";
import ClearTokens from "../components/clear-tokens/clear-tokens";

const AdminComponent = React.lazy(() => import('./admin'));
const ManagerComponent = React.lazy(() => import('./manager-data-provider'));

export const modules = [
    {component: ManagerComponent, link: '/manager/', title: 'Модуль менеджера', group: 102},
    {component: AdminComponent, link: '/admin/', title: 'Админка', group: 180},
];

function ProjectsRouter() {
    let history = useHistory();
    const {user} = useAuth();

    if (!user) {
        history.push(siteRoutes.user.auth)
    }

    return (
        <Switch>
            {
                modules.map((module, index) => {
                    if (!user || module.group > user.group_id) return null

                    return <Route path={module.link} key={index} component={module.component}/>;
                })
            }

            <Route exact path={siteRoutes.user.auth} component={AuthPage}/>
            <Route path={siteRoutes.clear} component={ClearTokens}/>
            <Route exact path={siteRoutes.home} component={HomePage}/>

        </Switch>
    )
}

export default ProjectsRouter;
