import {toFetch} from "../components/manager/utils/utils";
import {apiRoutes} from "./_routes";

type Request = {
    [k : string] : any
}

export const userApi = {
    /**
     * Проверка пользователя по токену
     * @param token
     */
    checkUser : (token : string) => toFetch(apiRoutes.user, {"method": "UserWhoami", token}).then(r => r.json()),

    /**
     * Авторизация пользователя
     * @param data
     */
    auth : (data : Request) => toFetch(apiRoutes.main, {"method": "authorize", ...data}).then(r => r.json())
};
