import moment from "moment";
import {actionsBookingInfo} from "../redux/reduсers/manager/booking-info";
import {toFetch} from "../components/manager/utils/utils";
import {apiRoutes} from "./_routes";
import {IBookingInfo} from "../types/booking-info";
import {actionsSnackbar} from "../redux/reduсers/snackbar";
import {IOrder, IOrderNew, IOrderUpdate} from "../types/order";
import {dayFormat} from "../constants";

type updateProps = { [k in keyof IBookingInfo]?: string | number } & {
    id: string,
    token: string | null,
    newStatus?: string,
};

export const bookingsApi = {
    /**
     * Получение заявок на определенный день
     *
     * @param json
     */
    getByDate: (json: { date: string, token: string }) => {
        console.log('getByDate');
        return (dispatch: any) => {
            const body = {
                method: "BookingList",
                token: json.token,
                timecode_from: moment(json.date).format(dayFormat.full),
                timecode_to: moment(json.date).add(1, 'd').format(dayFormat.full),
            };

            toFetch(apiRoutes.bookings, body)
                .then(r => r.json())
                .then(json => {
                    if (json.error) {
                        console.error(json.error_message);
                    } else {
                        dispatch(actionsBookingInfo.set(json.items));
                    }
                });
        }
    },


    /**
     * Обновление данных заявки
     */
    update: (json: updateProps, activeDay: string) => {
        return (dispatch: any) => {
            toFetch(apiRoutes.bookings, {method: "BookingEdit", ...json}).then(r => r.json())
                .then(json => {
                    // Артур ну епт.... ленивая ты программисто,
                    if (json.error === false) {
                        dispatch(actionsBookingInfo.update(json, activeDay))
                        dispatch(actionsSnackbar.show({type: "success", label: "Данные обновлены"}))
                    } else {
                        dispatch(actionsSnackbar.show({type: "error", label: "Ошибка связи с сервером"}))
                        console.error(json);
                    }
                })
                .catch(error => {
                    dispatch(actionsSnackbar.show({type: "error", label: "Ошибка связи с сервером"}))
                    console.error(error);
                })

        }
    },

    /**
     * Удаление брони
     */
    delete: (json: { id: string, token: string }) => {
        return (dispatch: any) => {
            toFetch(apiRoutes.bookings, {method: 'BookingDelete', ...json}).then(r => r.json())
                .then((json => {
                    if (json.error === false) {
                        dispatch(actionsSnackbar.show({type: "success", label: "Бронь удалена"}))
                    } else {
                        dispatch(actionsSnackbar.show({type: "error", label: "Ошибка связи с сервером"}))
                        console.error(json);
                    }
                }))
                .catch(error => {
                    dispatch(actionsSnackbar.show({type: "error", label: "Ошибка связи с сервером"}))
                    console.error(error);
                })
        }
    },

    /**
     * Сохранение брони
     *
     *  TODO рефакторнуть ато это какой-то секс по биатлону
     */
    save: (order: IOrder, token: string | undefined) => {
        return (dispatch: any) => {
            if (token === undefined) {
                dispatch(actionsSnackbar.showError("Токен отсутствует"));
            }

            const req = {
                ...order,
                method: "BookingEdit",
                token : token
            }

            toFetch(apiRoutes.bookings, req)
                .then(r=> r.json())
                .then((json) => {
                    if (json.error) {
                        dispatch(actionsSnackbar.showError(json.errorMessage))
                    } else {
                        const successText = order.id ? "Данные обновлены" : "Бронь добавлена";
                        dispatch(actionsSnackbar.showSuccess(successText))
                    }
                })
        }
    },

    /**
     * Создание брони и отсылка клиенту ссылки на оплату
     * @param order
     * @param token
     */
    addOrderAndSendPaymentSms: (order: IOrder, token: string, freezeBooking: number) => {
        const request = {
            "method": "ManagerBookingAddAndNotify",
            token,
            "table_id": order.table,
            "timecode": moment(order.date).add(order.time, 's').format(dayFormat.full),
            "num_guests": order.guests,
            "duration": `PT${order.duration}H`,
            "expiresIn": `PT${freezeBooking}H`,
            "status": "operatorLock",
            "deposit": order.deposit,
            "client": {
                "name": order.clientName,
                "phone": order.clientPhone,
                "comment": order.comment,
            }
        }

        return toFetch(apiRoutes.bookings, request).then(r => r.json());
    }
}
