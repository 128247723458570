import {TIME_SET} from "../../../constants";

const initialState = null;

export default function info(state = initialState, action) {
    switch (action.type) {
        case TIME_SET:
            return action.payload;
        default:
            return state;
    }
}

