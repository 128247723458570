import React from 'react'
import {Link} from "react-router-dom";
import {modules} from "./../routes";
import {useAuth} from "../contexts/auth";

const HomePage = () => {
    const {logout, user} = useAuth();
    if (!user) {
        return (
            <>
                <i>Загрузка...</i>
            </>
        );
    }
    const userGID = (user.groupId ? user.groupId : user.group_id);

    return (
        <>
            <div className="admin-page-selector">
                <ul className="admin-page-selector__container">
                    {
                        modules.map((module, index) => {
                            if (!user || module.group > userGID) return null

                            return <li key={index}><Link to={module.link}>{module.title}</Link></li>
                        })
                    }
                    <li>&nbsp;</li>
                    <li onClick={logout} style={{color: '#1EC025'}}>Выйти</li>
                </ul>
            </div>
        </>
    )
}

export default HomePage;
