import {toFetch} from "../components/manager/utils/utils";
import {apiRoutes} from "./_routes";
import {ICompanyTable} from "../types/table";

export const tablesApi = {
    /**
     * Получение списка столов
     *
     * @param token
     */
    get: (token: string) => {
        const request = {
            method: 'TablesList',
            token: token
        };

        return toFetch(apiRoutes.tables, request).then(r => r.json());
    },

    update: (table: ICompanyTable, token: string) => {
        const request = {
            "method": "TableEdit",
            "token": token,
            "id": table.id,
            "item": table,
        };

        return toFetch(apiRoutes.tables, request).then(r => r.json());
    }
}
