import {
    ORDER_STATUS_ARRIVED,
    ORDER_STATUS_BOOKED,
    ORDER_STATUS_LOCKED,
    ORDER_STATUS_NOT_ARRIVED,
    ORDER_STATUS_OPERATOR_LOCK
} from "../../../constants";

/**
 * Статусы бронирования заказа
 *
 * @param status
 * @return {boolean|{buttonText: boolean, statusClass: string, statusText: string}|{buttonText: string, statusClass: string, statusText: string}}
 */
export const getBookingStatus = (status: string) => {
    switch (status) {
        case ORDER_STATUS_NOT_ARRIVED:
            return {
                status: ORDER_STATUS_NOT_ARRIVED,
                color: getStatusColor(status),
                nextStatus: 'arrived',
                statusClass: 'is-not-arrived',
                text: 'Гость не пришел',
                buttonText: 'Гость на месте',
            };
        case ORDER_STATUS_OPERATOR_LOCK:
            return {
                status: ORDER_STATUS_OPERATOR_LOCK,
                nextStatus: ORDER_STATUS_BOOKED,
                color: getStatusColor(status),
                statusClass: 'is-locked',
                text: 'Ожидает подтверждения',
                buttonText: 'Подтвердить',
            };
        case ORDER_STATUS_LOCKED:
            return {
                color: getStatusColor(status),
                status: 'locked',
                nextStatus: 'booked',
                statusClass: 'is-locked',
                text: 'Ожидает подтверждения',
                buttonText: 'Подтвердить',
            };
        case ORDER_STATUS_BOOKED:
            return {
                color: getStatusColor(status),
                status: 'booked',
                nextStatus: 'arrived',
                statusClass: 'is-booked',
                text: 'Ожидание гостя',
                buttonText: 'Гость на месте',
            };
        case ORDER_STATUS_ARRIVED:
            return {
                color: getStatusColor(status),
                status: 'arrived',
                nextStatus: false,
                statusClass: 'is-arrived',
                text: 'Гость на месте',
                buttonText: false,
            };
        default:
            return false;

    }
};

/**
 * Склонение числительных
 *
 * @param number - число
 * @param titles - массив склонений из 3-х вариантов
 * @return {*}
 */
export const declOfNum = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

/** Проверяем не мертвый ли токен, если да - удаляем данные из sessionStorage и перегружаем страницу*/
export function isTokenWrong(json: { [key: string]: any }) {
    if (json.error && json.error_message === 'Wrong token. Get a new one.') {
        sessionStorage.removeItem('token');
        window.location.reload();
    }
}

/**
 * Немного оберки над fetch
 *
 * @param url
 * @param body
 * @return {Promise<Response>}
 */


/**
 * Общая обертка над Fetch
 *
 * @param url
 * @param request
 * @return {Promise<Response>}
 */
export function toFetch(url: string, request: { [k: string]: any }) {
    return fetch(url, {method: 'post', body: JSON.stringify(request)});
}

/**
 * обертка над Fetch c парсингом результата в JSON
 *
 * @param url
 * @param request
 * @return {Promise<any>}
 */
export function toFetchJSON(url: string, request: { [k: string]: any }) {
    return toFetch(url, request).then(r => r.json());
}

export function isFetchError(json: { [k: string]: any }) {
    if (json.error === true) console.error(json.errorMessage);
}

/**
 * Цвета статусов
 * @param str
 * @return {string}
 */
export function getStatusColor(str : string) {
    switch (str) {
        case ORDER_STATUS_OPERATOR_LOCK:
            return "#FFB629";
        case ORDER_STATUS_NOT_ARRIVED:
            return "#a7191e";
        case ORDER_STATUS_LOCKED:
            return "#FFB629";
        case ORDER_STATUS_BOOKED:
            return "#00B2FF";
        case ORDER_STATUS_ARRIVED:
            return "#5D5D5D";
        default:
            return "#1EC02530";

    }
}
