import {combineReducers} from 'redux';

import bookingInfo from './manager/booking-info';
import info from './manager/info';
import time from './manager/time';
import confirmWindow from "./confirms";
import snackbar from "./snackbar";

export const reducer = combineReducers({
    snackbar,
    confirmWindow,
    bookingInfo,
    info,
    time,
    // showDate,
});
